import { Button, Grid, Modal, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { Context } from "../context/PlatterContext";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  pt: 2,
  px: 4,
  pb: 3,
  height: 400,
  textAlign: "center",
};

export default function PlatterModal({
  platterModalVisible,
  setPlatterModalVisible,
  rolls,
  platterItem,
}) {
  const {
    addDishToPlatter,
    reduceDishFromPlatter,
    addPlatter,
    state: { platterItems },
  } = useContext(Context);

  const platterTotal = () => {
    return Object.keys(platterItems).reduce(
      (previousValue, currentValue) =>
        previousValue + platterItems[currentValue],
      0
    );
  };

  const rollCount = platterItem.name.indexOf("10") !== -1 ? 10 : 14;

  return (
    <Modal open={platterModalVisible}>
      <Box sx={{ ...modalStyle }}>
        <Grid>
          <h3>Platter</h3>
          <strong>You've added {platterTotal()} rolls</strong>
        </Grid>
        <Stack
          container
          style={{
            display: "flex",
            alignItems: "center",
            maxHeight: 300,
            overflow: "scroll",
          }}
        >
          {rolls.map((item) => (
            <Box>
              <span>{item.name}</span>
              <Button
                onClick={() => reduceDishFromPlatter(item.default_price.id)}
                style={{ minWidth: 32 }}
              >
                -
              </Button>
              {platterItems[item.default_price.id] ?? 0}
              <Button
                onClick={() => addDishToPlatter(item.default_price.id)}
                style={{ minWidth: 32 }}
                disabled={platterTotal() >= rollCount}
              >
                +
              </Button>
            </Box>
          ))}
        </Stack>
        <Button onClick={() => setPlatterModalVisible(false)}>Cancel</Button>
        <Button
          onClick={() => {
            addPlatter(platterItem.name, platterItems);
            setPlatterModalVisible(false);
          }}
          disabled={platterTotal() !== rollCount}
        >
          Add
        </Button>
      </Box>
    </Modal>
  );
}
