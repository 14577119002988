import React, { useReducer } from "react";
import createDataContext from "./CreateDataContext";

const initialState = {
  miniRollItems: {},
  open: false,
};

const miniRollReducer = (state, action) => {
  switch (action.type) {
    case "increment":
      if (state.miniRollItems[action.payload] >= 0) {
        return {
          ...state,
          miniRollItems: {
            ...state.miniRollItems,
            [action.payload]: state.miniRollItems[action.payload] + 1,
          },
        };
      }
      return {
        ...state,
        miniRollItems: {
          ...state.miniRollItems,
          [action.payload]: 1,
        },
      };
    case "decrement":
      if (state.miniRollItems[action.payload] - 1 > 0) {
        return {
          ...state,
          miniRollItems: {
            ...state.miniRollItems,
            [action.payload]: state.miniRollItems[action.payload] - 1,
          },
        };
      } else {
        let newState = { ...state };
        delete newState.miniRollItems[action.payload];
        return { ...newState };
      }
    case "add_miniroll":
      return {
        ...state,
        miniRollCount:
          state.miniRollCount +
          Object.keys(state.miniRollItems).reduce(
            (a, b) => a + state.miniRollItems[b],
            0
          ),
      };
    case "set_open":
      return {
        ...state,
        open: !state.open,
      };
    case "remove_miniroll":
      return initialState;
    default:
      return state;
  }
};

const addDishToMiniroll = (dispatch) => {
  return (id) => {
    dispatch({
      type: "increment",
      payload: id,
    });
  };
};

const reduceDishFromMiniRoll = (dispatch) => {
  return (id) => {
    dispatch({
      type: "decrement",
      payload: id,
    });
  };
};

const addMiniRoll = (dispatch) => {
  return (name, miniRollItems) => {
    dispatch({
      type: "add_miniroll",
      payload: { name, miniRollItems, open: false },
    });
  };
};

const setMiniRollOpen = (dispatch) => {
  return () => {
    dispatch({
      type: "set_open",
    });
  };
};

const removeMiniRoll = (dispatch) => {
  return () => {
    dispatch({
      type: "remove_miniroll",
    });
  };
};

export const { Provider, Context } = createDataContext(
  miniRollReducer,
  {
    addDishToMiniroll,
    reduceDishFromMiniRoll,
    addMiniRoll,
    setMiniRollOpen,
    removeMiniRoll,
  },
  initialState
);
