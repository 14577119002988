import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username !== "admin") return;
    fetch("/signin", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((res) => res.json())
      .then(({ token }) => {
        if (token) {
          localStorage.setItem("token", token);
          return navigate("/orders");
        }
        return navigate("/");
      })
      .catch((err) => console.error(err));
  };
  return (
    <Container>
      <Paper sx={{ margin: 10 }}>
        <Grid
          container
          spacing={3}
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <TextField
              label="Username"
              onChange={(event) => setUsername(event.target.value)}
              autoCapitalize={false}
              autoCorrect={false}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              type={"password"}
              onChange={(event) => setPassword(event.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth onClick={handleLogin}>
              {" "}
              Login{" "}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
