import { Box, Button, Card, Grid, Modal, Tab, Tabs } from "@mui/material";
import { Container } from "@mui/system";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import Cart, { PACK_PRICE } from "../components/cart/Cart";
import DishItem from "../components/DishItem";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Logo from "../Logo/Harumi_logo.jpeg";
import PlatterModal from "../components/PlatterModal";
import debounce from "lodash.debounce";
import MessengerCustomerChat from "react-messenger-customer-chat";
import MiniRollModal from "../components/MiniRollModal";

export const SPECIAL_PRICE = "Special Price";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  pt: 2,
  px: 4,
  pb: 3,
};

const CATEGORIES = [
  "platter",
  "roll",
  "nigiri",
  "inari",
  "pack",
  "sashimi",
  "salad",
  "ramen",
  "udon",
  "don",
  "bentobox",
  "ricepaper",
  "drink",
  "soup",
];

const CATEGORY_MAP = {
  roll: "Hand Roll",
  pack: "Sushi Pack",
  sashimi: "Sashimi",
  salad: "Salad",
  nigiri: "Nigiri & Inari",
  inari: "Inari",
  ramen: "Ramen",
  udon: "Udon",
  drink: "Soup & Drink",
  ricepaper: "Ricepaper",
  platter: "Platter",
  don: "Don",
  bentobox: "Bento Box",
  soup: "Soup & Drink",
};

const CATEGORY_MAP_POSITION = {
  platter: 0,
  roll: 1,
  nigiri: 2,
  inari: 2,
  pack: 3,
  sashimi: 3,
  salad: 3,

  // ramen: 4,
  // udon: 4,
  // don: 5,
  // bentobox: 8,
  ricepaper: 4,
  drink: 5,
};

export default function Menu() {
  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);
  const [cartItems, dispatch] = useReducer(dishReducer, {});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [platterModalVisible, setPlatterModalVisible] = useState(false);
  const [miniRollModalVisible, setMiniRollModalVisible] = useState(false);
  const [closed, setClosed] = useState(true);
  const packPrice = Number(
    (
      items.find((item) => item.name === SPECIAL_PRICE)?.default_price
        ?.unit_amount / 100
    ).toFixed(2)
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FoodSection = ({ category }) => {
    return (
      <>
        <h3 style={{ textTransform: "capitalize" }}>
          {CATEGORY_MAP[category]}
        </h3>
        <Box xs={12} id={category} sx={{ display: "flex", flexWrap: "wrap" }}>
          {items
            ?.filter((item) =>
              category === "drink" || category === "nigiri"
                ? category === "drink"
                  ? item.unit_label === "soup" || item.unit_label === "drink"
                  : item.unit_label === "inari" || item.unit_label === "nigiri"
                : item.unit_label === category
            )
            .sort((a, b) => b.listOrder - a.listOrder)
            ?.map((item) => (
              <DishItem
                item={item}
                setModalVisible={
                  category === "platter"
                    ? setPlatterModalVisible
                    : item.name.indexOf("Mini") !== -1
                    ? setMiniRollModalVisible
                    : setModalVisible
                }
                setSelectedItem={setSelectedItem}
                closed={closed}
                packPrice={packPrice}
              />
            ))}
        </Box>
      </>
    );
  };

  function dishReducer(state, action) {
    switch (action.type) {
      case "increment":
        if (state[action.payload] >= 0) {
          return { ...state, [action.payload]: state[action.payload] + 1 };
        }
        return { ...state, [action.payload]: 1 };
      case "decrement":
        if (state[action.payload] > 0) {
          if (state[action.payload] - 1 > 0) {
            return { ...state, [action.payload]: state[action.payload] - 1 };
          } else {
            let newState = { ...state };
            delete newState[action.payload];
            return { ...newState };
          }
        }
        return { ...state, [action.payload]: 0 };
      case "remove":
        let newState = { ...state };
        delete newState[action.payload];
        return { ...newState };
      default:
        return state;
    }
  }

  useEffect(() => {
    fetch("/shopStatus")
      .then((res) => res.json())
      .then((data) => setClosed(!data))
      .catch((err) => console.error(err));
    fetch("/items")
      .then((res) => {
        if (res.status === 201) {
          setClosed(true);
        }
        res.json().then(({ data }) => {
          if (!data) {
            return;
          }
          setItems(
            data
              .filter((item) => item.active === true)
              .map((item) => ({
                ...item,
                listOrder: Number(item.metadata?.pop)
                  ? Number(item.metadata?.pop)
                  : 0,
              }))
          );
        });
      })

      .catch((err) => console.error(err));
  }, []);

  const ItemModal = () => {
    if (!selectedItem) return;
    return (
      <Modal
        hideBackdrop
        open={modalVisible}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        onClose={() => setModalVisible(false)}
      >
        <Box sx={{ ...modalStyle, width: 200 }}>
          <h2 style={{ textAlign: "center" }} id="child-modal-title">
            {selectedItem.name}
          </h2>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid>
              <img width={100} src={selectedItem.images[0]} />
            </Grid>
            <Grid>
              Quantity:
              <Button
                onClick={() =>
                  dispatch({
                    type: "decrement",
                    payload: selectedItem.default_price.id,
                  })
                }
                style={{ minWidth: 32 }}
              >
                -
              </Button>
              {cartItems[selectedItem.default_price.id] ?? 0}
              <Button
                onClick={() =>
                  dispatch({
                    type: "increment",
                    payload: selectedItem.default_price.id,
                  })
                }
                style={{ minWidth: 32 }}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={() => {
                dispatch({
                  type: "remove",
                  payload: selectedItem.default_price.id,
                });
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => setModalVisible(false)}>Add</Button>
          </Grid>
        </Box>
      </Modal>
    );
  };

  const handleScroll = () => {
    for (let cat of CATEGORIES) {
      let foodSection = document.getElementById(cat);
      if (!foodSection) {
        continue;
      }
      if (
        (foodSection?.getBoundingClientRect().top > 0 &&
          foodSection?.getBoundingClientRect().top < 200) ||
        (foodSection?.getBoundingClientRect().bottom > 0 &&
          foodSection?.getBoundingClientRect().bottom < 800)
      ) {
        setValue(CATEGORY_MAP_POSITION[cat]);
      }
    }
  };

  const debouncedScrollHandler = useCallback(debounce(handleScroll, 50), []);

  return (
    <Container sx={{ margin: 1, padding: 1 }}>
      <Box
        style={{
          display: "flex",
          overflowX: "scroll",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          position: "fixed",
          top: 0,
          background: "white",
          zIndex: 5,
        }}
      >
        <Grid container>
          <Grid xs={12} sm={7}>
            <img width={"100%"} src={Logo} />
          </Grid>
        </Grid>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          style={{
            textAlign: "justify",
            display: "flex",
          }}
          variant={"scrollable"}
        >
          {/* Combine some of categories */}
          {CATEGORIES.filter((item) =>
            items.some((opt) => opt.unit_label === item)
          ).map((cat) => {
            if (["nigiri", "inari"].includes(cat)) {
              if (cat !== "inari") {
                return <Tab label={"Nigiri / Inari"} href={`#${cat}`} />;
              } else return;
            }
            if (["pack", "sashimi", "salad"].includes(cat)) {
              if (cat !== "sashimi" && cat !== "salad") {
                return (
                  <Tab
                    label={"Sushi Pack / Sashimi / Salad"}
                    href={`#${cat}`}
                  />
                );
              } else return;
            }
            if (["ramen", "udon"].includes(cat)) {
              if (cat !== "udon") {
                return <Tab label={"Ramen / Udon"} href={`#${cat}`} />;
              } else return;
            }
            if (["drink", "soup"].includes(cat)) {
              if (cat !== "soup") {
                return <Tab label={"Soup / Drink"} href={`#${cat}`} />;
              } else return;
            }
            return <Tab label={CATEGORY_MAP[cat]} href={`#${cat}`} />;
          })}
        </Tabs>
        <Box
          style={{ float: "right" }}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <a href="#cart" role="button">
            <AddShoppingCartIcon color="black" />
          </a>
        </Box>
      </Box>

      <Grid container marginTop={25}>
        <Grid item xs={12} md={8}>
          {closed && <h1>Sorry, we're closed now. </h1>}
          <div
            style={{ overflow: "scroll", maxHeight: "90vh" }}
            onScroll={debouncedScrollHandler}
          >
            {CATEGORIES.filter((item) =>
              items.some((opt) => opt.unit_label === item)
            ).map((cat) => {
              if (["soup", "inari"].includes(cat)) {
                return;
              }
              return <FoodSection category={cat} />;
            })}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div id="cart" xs={{ position: "fixed", marginLeft: 20 }}>
            <h3>Cart</h3>
            {items && (
              <Cart cartItems={cartItems} items={items} dispatch={dispatch} />
            )}
          </div>
        </Grid>
      </Grid>
      <ItemModal />
      {selectedItem && (
        <PlatterModal
          rolls={items.filter(
            (item) =>
              item.unit_label === "roll" &&
              item.name.toLowerCase().indexOf("mini") === -1
          )}
          platterModalVisible={platterModalVisible}
          setPlatterModalVisible={setPlatterModalVisible}
          platterItem={selectedItem}
        />
      )}
      {selectedItem && (
        <MiniRollModal
          rolls={items.filter(
            (item) =>
              item.unit_label === "roll" &&
              item.name.toLowerCase().indexOf("mini") === -1
          )}
          miniRollModalVisible={miniRollModalVisible}
          setMiniRollModalVisible={setMiniRollModalVisible}
          platterItem={selectedItem}
        />
      )}
      {/* <a
        style={{
          width: 48,
          height: 48,
          position: "fixed",
          bottom: 20,
          right: 40,
          borderRadius: "50%",
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
          padding: 0,
        }}
        role="button"
        target="_blank"
        href="https://www.facebook.com/Harumi-Sushi-Ulverstone-104280852260359"
      >
        <img src={Facebook} width={48} height={48} />
      </a> */}
      <div style={{ position: "fixed", bottom: 20, right: 40 }}>
        <MessengerCustomerChat
          pageId="104280852260359"
          appId="590762149157194"
        />
      </div>
    </Container>
  );
}
