import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

export default function DishItem({
  item,
  setModalVisible,
  setSelectedItem,
  closed,
  packPrice,
}) {
  return (
    <Grid
      className="dish-item-container"
      xs={item.unit_label === "platter" ? 8 : 5}
      sm={item.unit_label === "platter" ? 6 : 4}
    >
      <CardContent>
        <Stack container style={{ display: "flex", alignItems: "center" }}>
          <CardMedia
            style={{ borderRadius: 5 }}
            component="img"
            image={item.images[0]}
          />
          <Grid style={{ textAlign: "center", alignItems: "center" }}>
            <Typography color="text.secondary" gutterBottom>
              {item.name}
            </Typography>
            {item.unit_label === "roll" &&
              item.name.toLowerCase().indexOf("mini") === -1 && (
                <Typography color="text.secondary" gutterBottom>
                  Any 3 For ${packPrice?.toFixed(2)}
                </Typography>
              )}
            <Typography color="text.secondary" gutterBottom>
              {`$ ${(Number(item.default_price.unit_amount) / 100).toFixed(2)}`}
            </Typography>
          </Grid>
          <Button
            onClick={() => {
              setSelectedItem(item);
              setModalVisible(true);
            }}
            disabled={closed}
          >
            Add to Cart
          </Button>
        </Stack>
        {/* <CardActions> */}

        {/* </CardActions> */}
      </CardContent>
    </Grid>
  );
}
