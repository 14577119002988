import {
  Box,
  Button,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 2,
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchOrders();
    fetchOpen();

    const interval = setInterval(() => {
      fetchOrders();
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);

  const handleCompleteOrder = async (chargeId) => {
    try {
      const res = await fetch("/completeOrder", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
        redirect: "follow",
        method: "POST",
        body: JSON.stringify({
          chargeId,
        }),
      });
      fetchOrders();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdatePassword = () => {
    if (!password) {
      return alert("请输入密码");
    }
    if (!confirmedPassword) {
      return alert("请再次确认密码");
    }
    if (password !== confirmedPassword) {
      return alert("密码不一致");
    }
    fetch("/resetpassword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
      redirect: "follow",
      method: "POST",
      body: JSON.stringify({
        password,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return alert("密码修改成功");
        }
        return alert("密码修改失败，请稍后重试");
      })
      .catch((err) => alert("密码修改失败，请稍后重试"))
      .finally(setModalOpen(false));
  };

  const fetchOrders = () => {
    if (!token) {
      navigate("/signin");
    }
    fetch("/transactions", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setOrders(data.filter((item) => item.status === "complete"));
      })
      .catch((err) => console.error(err));
  };

  const fetchOpen = () => {
    fetch("/shopStatus")
      .then(res=>res.json())
      .then(data=>setOpen(data))
      .catch(err=>console.error(err))
  }

  const handleOpenShop = (event) => {
    fetch("/openShop",{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        status: event.target.checked
      })
    }).then(res=>{
      if(res.status===200){
        setOpen(!event.target.checked)
      }
    })
    .catch(err=>console.error(err))
  }

  const Row = (props) => {
    const { row } = props;
    const {
      customer_details: { name, email, phone },
      payment_intent: {
        created,
        charges: { data },
      },
    } = row;
    const { description, metadata, id: chargeId } = data[0];
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>{name}</TableCell>
          <TableCell align="right">{email}</TableCell>
          <TableCell align="right">{phone}</TableCell>
          <TableCell align="right">
            <Collapse in={true} unmountOnExit>
              {Object.keys(metadata).map((item) => (
                <p style={{ margin: 0 }}>{`${item}: ${metadata[item]}`}</p>
              ))}
            </Collapse>
          </TableCell>
          <TableCell align="right">
            {description}
            {description !== "done" && (
              <Button onClick={() => handleCompleteOrder(chargeId)}>
                完成订单
              </Button>
            )}
          </TableCell>
          <TableCell align="right">
            {new Date(created * 1000).toLocaleDateString()}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Container>
      <Grid container sx={{ marginTop: 10 }}>
        <Grid>
          <Button onClick={() => setModalOpen(true)}>修改密码</Button>
          <Button
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
            }}
          >
            退出
          </Button>
          <FormControlLabel control={          <Switch
            checked={open}
            onChange={handleOpenShop}
            inputProps={{ 'aria-label': 'controlled' }}
            
          />} label={`状态： ${open?"开":"关"} `} />

        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>客人姓名</TableCell>
              <TableCell align="right">邮箱</TableCell>
              <TableCell align="right">电话</TableCell>
              <TableCell align="right">订单详情</TableCell>
              <TableCell align="right">取餐时间</TableCell>
              <TableCell align="right">下单日期</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((item) => (
              <Row key={item.id} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen}>
        <Box sx={style}>
          <Grid
            container
            spacing={3}
            direction={"column"}
            justify={"center"}
            alignItems={"center"}
          >
            <Grid item xs={12}>
              <TextField
                aria-readonly
                label="用户名"
                value="admin"
                disabled
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="密码"
                type={"password"}
                onChange={(event) => setPassword(event.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="确认密码"
                type={"password"}
                onChange={(event) => setConfirmedPassword(event.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => setModalOpen(false)}>取消</Button>
              <Button
                onClick={handleUpdatePassword}
                disabled={
                  !password ||
                  !confirmedPassword ||
                  password !== confirmedPassword
                }
              >
                {" "}
                提交{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}
