import { Alert, Button, Icon } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./App.css";
import Menu from "./pages/Menu";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Orders from "./pages/Orders";
import Signin from "./pages/Signin";

export default function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <>
      {message && (
        <Alert
          severity={message.indexOf("canceled") !== -1 ? "warning" : "success"}
          onClose={() => {
            setMessage("");
          }}
        >
          {message}
        </Alert>
      )}
      <Router>
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/signin" element={<Signin />} />
        </Routes>
      </Router>
    </>
  );
}
