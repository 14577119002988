import { Button, Grid, Modal, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { Context } from "../context/MiniRollContext";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  pt: 2,
  px: 4,
  pb: 3,
  height: 400,
  textAlign: "center",
};

export default function MiniRollModal({
  miniRollModalVisible,
  setMiniRollModalVisible,
  rolls,
  platterItem,
}) {
  const {
    addDishToMiniroll,
    reduceDishFromMiniRoll,
    addMiniRoll,
    setMiniRollOpen,
    removeMiniRoll,
    state: { miniRollItems },
  } = useContext(Context);

  const platterTotal = () => {
    return Object.keys(miniRollItems).reduce(
      (previousValue, currentValue) =>
        previousValue + miniRollItems[currentValue],
      0
    );
  };

  return (
    <Modal open={miniRollModalVisible}>
      <Box sx={{ ...modalStyle }}>
        <Grid>
          <h3>Mini Rolls</h3>
        </Grid>
        <Stack
          container
          style={{
            display: "flex",
            alignItems: "center",
            maxHeight: 300,
            overflow: "scroll",
          }}
        >
          {rolls.map((item) => (
            <Box>
              <span>{item.name}</span>
              <Button
                onClick={() => reduceDishFromMiniRoll(item.default_price.id)}
                style={{ minWidth: 32 }}
              >
                -
              </Button>
              {miniRollItems[item.default_price.id] ?? 0}
              <Button
                onClick={() => addDishToMiniroll(item.default_price.id)}
                style={{ minWidth: 32 }}
              >
                +
              </Button>
            </Box>
          ))}
        </Stack>
        <Button onClick={() => setMiniRollModalVisible(false)}>Cancel</Button>
        <Button
          onClick={() => {
            addMiniRoll(platterItem.name, miniRollItems);
            setMiniRollModalVisible(false);
          }}
          disabled={platterTotal() < 1}
        >
          Add
        </Button>
      </Box>
    </Modal>
  );
}
