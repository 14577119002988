import React, { useReducer } from "react";
import createDataContext from "./CreateDataContext";

const initialState = {
  platters: [],
  platterItems: {},
};

const platterReducer = (state, action) => {
  switch (action.type) {
    case "increment":
      if (state.platterItems[action.payload] >= 0) {
        return {
          ...state,
          platterItems: {
            ...state.platterItems,
            [action.payload]: state.platterItems[action.payload] + 1,
          },
        };
      }
      return {
        ...state,
        platterItems: {
          ...state.platterItems,
          [action.payload]: 1,
        },
      };
    case "decrement":
      if (state.platterItems[action.payload] - 1 > 0) {
        return {
          ...state,
          platterItems: {
            ...state.platterItems,
            [action.payload]: state.platterItems[action.payload] - 1,
          },
        };
      } else {
        let newState = { ...state };
        delete newState.platterItems[action.payload];
        return newState;
      }
    case "remove":
      let newState = { ...state };
      delete newState[action.payload];
      return newState;
    case "add_platter":
      return {
        ...state,
        platters: [...state.platters, action.payload],
        platterItems: {},
      };
    case "set_open":
      return {
        ...state,
        platters: state.platters.map((item, idx) =>
          idx === action.payload ? { ...item, open: !item.open } : item
        ),
      };
    case "remove_platter":
      return {
        ...state,
        platters: state.platters.filter((item, idx) => idx !== action.payload),
      };
    default:
      return state;
  }
};

const addDishToPlatter = (dispatch) => {
  return (id) => {
    dispatch({
      type: "increment",
      payload: id,
    });
  };
};

const reduceDishFromPlatter = (dispatch) => {
  return (id) => {
    dispatch({
      type: "decrement",
      payload: id,
    });
  };
};

const addPlatter = (dispatch) => {
  return (name, platterItems) => {
    dispatch({
      type: "add_platter",
      payload: { name, platterItems, open: false },
    });
  };
};

const setPlatterOpen = (dispatch) => {
  return (idx) => {
    dispatch({
      type: "set_open",
      payload: idx,
    });
  };
};

const removePlatter = (dispatch) => {
  return (idx) => {
    dispatch({
      type: "remove_platter",
      payload: idx,
    });
  };
};

export const { Provider, Context } = createDataContext(
  platterReducer,
  {
    addDishToPlatter,
    reduceDishFromPlatter,
    addPlatter,
    setPlatterOpen,
    removePlatter,
  },
  initialState
);
