import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Context as PlatterContext } from "../../context/PlatterContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { Context as MiniRollContext } from "../../context/MiniRollContext";
import { SPECIAL_PRICE } from "../../pages/Menu";

const TIMES = [
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
];

export const PACK_PRICE = "price_1OTFVQFAideSn60yF8DrcIo8";

export const THREE_ROLL_PACK_PRICE = 12.5;

const calculateRollsPrice = (rolls, packPrice) => {
  if (!rolls.length) return 0;
  if (rolls.length === 3) {
    return packPrice;
  }
  if (rolls.length < 3) {
    return rolls.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
  }
  if (rolls.length > 3) {
    rolls.splice(0, 3);
    return packPrice + calculateRollsPrice(rolls);
  }
};

const convertItems = (items) => {
  if (!items.length) return [];
  if (items.length === 3) {
    return [PACK_PRICE];
  }
  if (items.length < 3) {
    return items;
  }
  if (items.length > 3) {
    items.splice(0, 3);
    return [PACK_PRICE, ...convertItems(items)];
  }
};

export default function Cart({ cartItems, items, dispatch }) {
  const [time, setTime] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [note, setNote] = useState("");
  const [orderDate, setOrderDate] = useState(undefined);
  const packPrice = Number(
    (
      items.find((item) => item.name === SPECIAL_PRICE)?.default_price
        .unit_amount / 100
    ).toFixed(2) || THREE_ROLL_PACK_PRICE
  );
  const {
    state: { platters },
    setPlatterOpen,
    removePlatter,
  } = useContext(PlatterContext);
  const {
    state: { miniRollItems, open: miniRollOpen },
    setMiniRollOpen,
    removeMiniRoll,
  } = useContext(MiniRollContext);
  let displayItems = items?.filter((item) => cartItems[item.default_price.id]);

  useEffect(() => {
    setSubtotal(0);
    if (!cartItems && !platters) return;
    // if (!Object.keys(cartItems).some((item) => cartItems[item] > 0)) return;
    let rolls = [];
    let sub = 0;

    displayItems?.forEach((item) => {
      if (item.unit_label === "roll") {
        rolls = rolls.concat(
          Array(cartItems[item.default_price.id]).fill(
            item.default_price.unit_amount / 100
          )
        );
      } else {
        sub +=
          (item.default_price.unit_amount / 100) *
          cartItems[item.default_price.id];
      }
    });

    //add rolls amount
    sub += calculateRollsPrice(rolls, packPrice);

    //add platters amount
    sub += platters?.reduce(
      (p, c) =>
        p +
        items.find((item) => item.name === c.name)?.default_price.unit_amount /
          100,
      0
    );

    //add miniRolls amount
    sub += Object.keys(miniRollItems)?.reduce(
      (a, b) =>
        a +
        (items.find((item) => item.name.toLowerCase().indexOf("mini") !== -1)
          ?.default_price.unit_amount /
          100) *
          miniRollItems[b],
      0
    );

    setSubtotal(sub);
  }, [cartItems, platters, miniRollItems]);

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setOrderDate(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const onCheckOut = async () => {
    let rolls = [];
    let nonRolls = [];
    let platterItems = [];
    displayItems
      .filter((item) => item.unit_label === "roll")
      ?.forEach((item) => {
        rolls = rolls.concat(
          Array(cartItems[item.default_price.id]).fill(item.default_price.id)
        );
      });
    displayItems
      .filter((item) => item.unit_label !== "roll")
      ?.forEach((item) => {
        nonRolls = nonRolls.concat(
          Array(cartItems[item.default_price.id]).fill(item.default_price.id)
        );
      });
    let line_items = [
      ...convertItems(rolls)?.map((item) => ({
        price: item,
        quantity: 1,
      })),
      ...nonRolls?.map((item) => ({
        price: item,
        quantity: 1,
      })),
      ...platters?.map((item) => ({
        price: items.find((opt) => opt.name === item.name).default_price.id,
        quantity: 1,
      })),
    ];
    if (Object.keys(miniRollItems).length > 0) {
      line_items = [
        ...line_items,
        {
          price: items.find(
            (item) => item.name.toLowerCase().indexOf("mini") !== -1
          ).default_price.id,
          quantity: Object.keys(miniRollItems).reduce(
            (a, b) => a + miniRollItems[b],
            0
          ),
        },
      ];
    }

    platters.forEach((item, idx) => {
      Object.keys(item.platterItems).forEach((key) => {
        platterItems.push({
          name: `
          (${item.name.match(/10/) ? "S" : "L"}-${idx + 1})${
            items.find((opt) => opt.default_price.id === key).name
          }`,
          quantity: item.platterItems[key],
        });
      });
    });

    try {
      const res = await fetch("/create-checkout-session", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
        redirect: "follow",
        method: "POST",
        body: JSON.stringify({
          line_items,
          pick_up_time: `${
            orderDate === new Date().toDateString() ? "Today" : "Tomorrow"
          } ${new Date(orderDate).toLocaleDateString("en-US")} ${time}`,
          note,
          order_items: [
            ...displayItems?.map((item) => ({
              name: item.name,
              quantity: cartItems[item.default_price.id],
            })),
            ...platterItems?.map((item) => ({
              name: item.name.substring(0, Math.min(item.name.length, 40)),
              quantity: item.quantity,
            })),
            ...Object.keys(miniRollItems)?.map((item) => ({
              name: `${
                items.find((opt) => opt.default_price.id === item).name
              } (mini)`,
              quantity: miniRollItems[item],
            })),
            ...platters?.map((item) => ({
              name: item.name,
              quantity: 1,
            })),
          ],
        }),
      });
      const { url } = await res.json();
      window.location.replace(url);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card>
      <CardContent>
        {/* render non-platter items */}
        {displayItems?.map((item) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <strong>{item.name}</strong>
            <Button
              onClick={() =>
                dispatch({
                  type: "decrement",
                  payload: item.default_price.id,
                })
              }
              style={{ minWidth: 32 }}
            >
              -
            </Button>
            <p style={{ marginTop: 1, marginBottom: 1 }}>
              {cartItems[item.default_price.id]}
            </p>
            <Button
              onClick={() =>
                dispatch({
                  type: "increment",
                  payload: item.default_price.id,
                })
              }
              style={{ minWidth: 32 }}
            >
              +
            </Button>
          </div>
        ))}
        {/* render platters */}
        {platters.map((item, idx) => (
          <List>
            <div style={{ display: "flex" }}>
              <ListItemButton
                style={{ background: "#eeeeee", paddingLeft: 10 }}
                onClick={() => setPlatterOpen(idx)}
              >
                <strong>{item.name}</strong>
                {item.open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Button onClick={() => removePlatter(idx)}>Remove</Button>
            </div>
            <Collapse in={item.open} timeout="auto" unmountOnExit>
              {Object.keys(item.platterItems).map((pitem) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  padding={1}
                >
                  <div>
                    {
                      items.find((item) => item.default_price.id === pitem)
                        ?.name
                    }
                  </div>
                  <div>{item.platterItems[pitem]}</div>
                </Box>
              ))}
            </Collapse>
          </List>
        ))}
        {Object.keys(miniRollItems).length > 0 && (
          <List>
            <div style={{ display: "flex" }}>
              <ListItemButton
                style={{ background: "#eeeeee", paddingLeft: 10 }}
                onClick={() => setMiniRollOpen()}
              >
                <strong>
                  Mini Roll :{" "}
                  {Object.keys(miniRollItems).reduce(
                    (a, b) => a + miniRollItems[b],
                    0
                  )}
                </strong>
                {miniRollOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Button onClick={removeMiniRoll}>Remove</Button>
            </div>
            <Collapse in={miniRollOpen} timeout="auto" unmountOnExit>
              {Object.keys(miniRollItems).map((item) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  padding={1}
                >
                  <div>
                    {items.find((opt) => opt.default_price.id === item)?.name}
                  </div>
                  <div>{miniRollItems[item]}</div>
                </Box>
              ))}
            </Collapse>
          </List>
        )}
        <h3>Subtotal: {subtotal.toFixed(2)}</h3>
        <FormControl fullWidth>
          <InputLabel id="date-select-label">Select pick up date</InputLabel>
          <Select
            labelId="date-select-label"
            value={orderDate}
            label="date"
            onChange={handleDateChange}
          >
            {new Date().getDay() !== 2 && (
              <MenuItem value={new Date().toDateString()}>
                Today {new Date().toLocaleDateString("en-US")}
              </MenuItem>
            )}

            {new Date().getDay() !== 1 && (
              <MenuItem
                value={new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000
                ).toDateString()} //get tomorrow's date
              >
                Tomorrow{" "}
                {new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000
                ).toLocaleDateString("en-US")}
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel id="time-select-label">Select pick up time</InputLabel>
          <Select
            labelId="time-select-label"
            id="time-select"
            value={time}
            label="time"
            onChange={handleChange}
          >
            {(new Date(orderDate).getDay() !== 0
              ? TIMES
              : TIMES.slice(0, 9)
            ).map((item) => {
              return orderDate === new Date().toDateString() &&
                new Date().setHours(
                  Number(item.split(":")[0]),
                  Number(item.split(":")[1]),
                  0
                ) >
                  Date.now() + 60 * 60 * 1000 &&
                new Date(orderDate).getDay() !== 2 ? (
                <MenuItem value={item}>{item}</MenuItem>
              ) : new Date(orderDate) > new Date() &&
                new Date(orderDate).getDay() !== 2 ? (
                <MenuItem value={item}>{item}</MenuItem>
              ) : null;
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Additional Note (Optional)</InputLabel>
          <Input
            id="my-input"
            aria-describedby="my-helper-text"
            onChange={handleNoteChange}
          />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button disabled={time === ""} onClick={onCheckOut}>
          Checkout
        </Button>
      </CardActions>
    </Card>
  );
}
